import { Configure, connectAutoComplete } from "react-instantsearch-dom";
import React, { useEffect, useState } from "react";
import {
  HeaderSearch,
  handleButton,
  SearchPriceFilter,
  formatPrice
} from "benlux-ecommerce-ui";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch, Index, SearchBox, Hits } from "react-instantsearch-dom";
import { connectRange } from "react-instantsearch-dom";
import "rheostat/initialize";
import Rheostat from "rheostat";
import "rheostat/css/rheostat.css";
import withAlgoliaSearch from "./withAlgoliaSearch";

const RangeSlider = ({ min, max, currentRefinement, canRefine, refine }) => {
  if (min === max) {
    return null;
  }
  const onChange = ({ values: [min, max] }) => {
    if (currentRefinement.min !== min || currentRefinement.max !== max) {
      refine({ min, max });
    }
  };

  return (
    <SearchPriceFilter>
      <Rheostat
        min={min}
        max={max}
        values={[currentRefinement.min, currentRefinement.max]}
        onChange={onChange}
        className="rheostatUI"
        snap
        handle={handleButton}
      />
    </SearchPriceFilter>
  );
};

export const CustomRangeSlider = connectRange(RangeSlider);

const searchClient = algoliasearch(
  "JL0T5UHGEA",
  "bf7d6d281c68a185caa38546d91c341b"
);

const Autocomplete = ({
  hits,
  currentRefinement,
  component,
  history,
  location,
  onHitClick
}) => {
  const Hits = component;
  return (
    <Hits
      hits={hits}
      seeAllResultsPath={`/search/${encodeURIComponent(currentRefinement)}`}
      currentQuery={currentRefinement}
      onHitClick={onHitClick}
    />
  );
};

export const CustomAutocomplete = connectAutoComplete(Autocomplete);

export const SearchProvider = withAlgoliaSearch(
  ({
    query,
    searchHitsComponent,
    history,
    location,
    onHitClick,
    algoliaSearchConfig
  }) => {
    const { indexNamePrefix } = algoliaSearchConfig;
    const handleKeyPress = () => {
      const { search } = location;
      const queryParams = new URLSearchParams(search);
      const next =
        queryParams.get("next") ||
        `/search/${encodeURIComponent(currentRefinement)}`;
      if (currentRefinement.length > 0) {
        history.replace(next);
      }
    };

    const [searchState, setSearchState] = useState({});

    useEffect(() => {
      const updatedSearchState = {
        ...searchState,
        query,
        optionalWords: query
      };
      setSearchState(updatedSearchState);
    }, [query]);

    /*if (typeof window === 'undefined') {
    return null;
  }*/

    return (
      <InstantSearch
        searchClient={searchClient}
        searchState={searchState}
        onSearchStateChange={searchState => {
          setSearchState(searchState);
        }}
        indexName={indexNamePrefix + "default_categories"}
      >
        <Index indexName={indexNamePrefix + "default_categories"}>
          <Configure filters={"include_in_menu=1"} />
        </Index>
        <Index indexName={indexNamePrefix + "default_products"} />
        <Index indexName={indexNamePrefix + "default_pages"} />
        <Configure hitsPerPage={6} />
        <div style={{ display: "none" }}>
          <SearchBox />
        </div>
        <CustomAutocomplete
          component={searchHitsComponent}
          history={history}
          location={location}
          onHitClick={onHitClick}
        />
      </InstantSearch>
    );
  }
);
