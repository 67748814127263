import React, { useEffect, useRef } from "react";
import "./SliderTop.css";
import arrow from "../../assets/arrow-slider.svg";
import * as styles from "./SliderTop.css";
import Carousel from "react-multi-carousel";
import { Link } from "react-router-dom";

type Props = {
  images?: any;
  contents?: [
    {
      id: number;
      alt: string;
      path: string;
      desktopImg: string;
      mobileImg: string;
    }
  ];
};

const CustomRightArrow = ({ onClick, ...rest }) => {
  const {
    carouselState: { currentSlide, deviceType },
  } = rest;
  return (
    <button onClick={() => onClick()} className={styles.slideArrow}>
      <img src={arrow} alt={currentSlide} />
    </button>
  );
};

const CustomLeftArrow = ({ onClick, ...rest }) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType },
  } = rest;
  return (
    <button
      onClick={() => onClick()}
      className={styles.slideArrow + " " + styles.left}
    >
      <img src={arrow} alt={currentSlide} />
    </button>
  );
};

function SliderTop({ contents }: Props) {
  const carousel = useRef(null);
  const [showArrows, setShowArrows] = React.useState(false);

  useEffect(() => {
    setShowArrows(true);
  }, []);

  return (
    <div className={styles.wrapper}>
      <Carousel
        ref={carousel}
        containerClass={styles.carousel}
        dotListClass={styles.carouselDots}
        itemClass={styles.item}
        swipeable
        infinite={true}
        draggable={false}
        arrows={showArrows}
        customLeftArrow={<CustomLeftArrow />}
        customRightArrow={<CustomRightArrow />}
        autoPlay={false}
        autoPlaySpeed={8000}
        ssr
        showDots
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: 1,
          },
          mobile: {
            breakpoint: {
              max: 768,
              min: 0,
            },
            items: 1,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 768,
            },
            items: 1,
          },
        }}
        deviceType={"desktop"}
      >
        {contents.map((content, index) => (
          <Link
            to={content.path}
            className={styles.itemToRename}
            key={content.id}
          >
            <picture>
              <source media="(max-width: 767px)" srcSet={content.mobileImg} />
              <source media="(min-width: 768px)" srcSet={content.desktopImg} />
              <img
                src={content.desktopImg}
                alt={content.alt}
                loading={index > 0 ? "lazy" : undefined}
                fetchpriority={index === 0 ? "high" : undefined}
              />
            </picture>
          </Link>
        ))}
      </Carousel>
    </div>
  );
}

export default SliderTop;
