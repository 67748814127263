import React, { useRef, useState } from "react";
import styles from "./HeaderSearch.css";
import cross from "../../assets/cross.png";
import { ProductSearchbar } from "../index";
import { Link } from "react-router-dom";
import { getPath } from "../../utils/getPath";

type HeaderSearchProps = {
  searchProvider: any;
  history: any;
};

function SearchHits({ hits, seeAllResultsPath, currentQuery, onHitClick }) {
  const categoriesHits = hits.find(
    (cat: any) => cat.index.indexOf("default_categories") > 0
  );
  const productCategoriesHits = categoriesHits?.hits.filter(
    (hit: any) => hit.path.indexOf("Catégories / ") === 0
  );
  const brandsHits = categoriesHits?.hits.filter(
    (hit: any) => hit.path.substring(0, 7) === "Marques" && hit.level === 3
  );

  return (
    <div className={styles.dropDownSearch}>
      <ul>
        {hits
          .filter((c) => c.hits.length > 0)
          .map((category: any) => (
            <li key={category.index} className={styles.index}>
              {category.index.indexOf("default_categories") > 0 && (
                <>
                  {productCategoriesHits.length > 0 && (
                    <>
                      <h2>Catégories</h2>
                      <ul className={styles.gridRow}>
                        {productCategoriesHits.map((hit: any) => (
                          <li key={"category-hit-" + hit.objectID}>
                            <Link
                              to={getPath(hit.url)}
                              className={styles.hit}
                              onClick={onHitClick}
                            >
                              {hit.path.replace("Catégories / ", "")}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                  {brandsHits.length > 0 && (
                    <>
                      <h2>Marques</h2>
                      <ul className={styles.gridRow}>
                        {brandsHits.map((hit: any) => (
                          <li key={"brand-hit-" + hit.objectID}>
                            <Link
                              to={getPath(hit.url)}
                              className={styles.hit}
                              onClick={onHitClick}
                            >
                              {hit.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </>
              )}
              {category.index.indexOf("default_products") > 0 &&
                category.hits.length > 0 && (
                  <>
                    <h2>Produits</h2>
                    <ul className={styles.grid + " " + styles.productHits}>
                      {category.hits.map((hit: any) => (
                        <li key={"product-hit-" + hit.objectID}>
                          <ProductSearchbar hit={hit} onClick={onHitClick} />
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              {category.index.indexOf("default_pages") > 0 &&
                category.hits.length > 0 && (
                  <>
                    <h2>Inspirations</h2>
                    <ul className={styles.gridRow}>
                      {category.hits.map((hit: any) => (
                        <li key={"page-hit-" + hit.objectID}>
                          <Link
                            to={`/inspirations/${hit.slug}`}
                            className={styles.hit}
                            onClick={onHitClick}
                          >
                            {hit.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
            </li>
          ))}
      </ul>
      {hits.length > 0 && currentQuery.length > 0 && (
        <div className={styles.allResultsLink}>
          <Link to={seeAllResultsPath} onClick={onHitClick}>
            Voir tous les résultats
          </Link>
        </div>
      )}
    </div>
  );
}

import magnifyIcon from "./magnify.svg";

function HeaderSearch({ searchProvider, history }: HeaderSearchProps) {
  const [searchQuery, setSearchQuery] = useState("");
  const [dropdownIsOpened, setDropdownOpened] = useState(false);
  const inputRef = useRef(null);

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      if (searchQuery.length > 0) {
        setDropdownOpened(false);
        history.replace(`/search/${encodeURIComponent(searchQuery)}`);
      }
    }
  };

  const setURIValue = (value: any) => {
    if (value.length > 0) {
      setDropdownOpened(false);
    }
  };

  return (
    <div className={styles.wrapper + (dropdownIsOpened ? " active" : "")}>
      <div className={styles.box + (dropdownIsOpened ? " active" : "")}>
        <form
          role="search"
          spellCheck={"false"}
          className={styles.headerSearch + (dropdownIsOpened ? " active" : "")}
        >
          <input
            type="search"
            ref={inputRef}
            onChange={(event) => setSearchQuery(event.target.value)}
            onFocus={() => setDropdownOpened(true)}
            placeholder="Rechercher un produit, une marque, ..."
            autoComplete={"off"}
            onKeyPress={(e: any) => handleKeyPress(e)}
            aria-label={"Recherche parmi le contenu du site"}
          />
          <div
            onClick={() => {
              setDropdownOpened(true);
              inputRef.current?.focus();
            }}
            className={styles.searchIcon}
          >
            <img src={magnifyIcon} alt={"Rechercher"} />
          </div>
        </form>
        <div
          className={styles.separator + (dropdownIsOpened ? " active" : "")}
        />
        {dropdownIsOpened &&
          React.cloneElement(searchProvider, {
            query: searchQuery,
            searchHitsComponent: SearchHits,
            onHitClick: () => setDropdownOpened(false),
          })}
      </div>
      <div
        className={styles.background + (dropdownIsOpened ? " active" : "")}
        onClick={() => setDropdownOpened(false)}
      />
      <div
        className={styles.closeSearch}
        onClick={() => setDropdownOpened(false)}
      >
        <img src={cross} alt="menu" />
      </div>
    </div>
  );
}

export default HeaderSearch;
