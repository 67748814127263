import React from "react";
import delivery from "../../assets/delivery.svg";
import turnback from "../../assets/turnback.svg";
import lock from "../../assets/lock.svg";
import canceling from "../../assets/canceling.svg";
import styles from "./FooterTop.css";

function FooterTop() {
  const startTime = new Date("2024-05-03 12:00").getTime() / 1000;
  const endTime = new Date("2024-05-05 23:59").getTime() / 1000;

  const nowTime = new Date().getTime() / 1000;
  let freeShippingThreshold = 60;
  if (nowTime > startTime && nowTime < endTime) {
    freeShippingThreshold = 30;
  }

  return (
    <div className={styles.container}>
      <section className={styles.block}>
        <img src={delivery} alt={"Livraison"} />
        <span>
          Livraison offerte dès {freeShippingThreshold}&nbsp;€ d'achats
        </span>
      </section>
      <section className={styles.block}>
        <img src={lock} alt={"Paiement sécurisé"} />
        <span>
          Paiement sécurisé
          <br /> avec Adyen
        </span>
      </section>
      <section className={styles.block}>
        <img src={turnback} alt={"turnback"} />
        <span>
          Faire&nbsp;un&nbsp;retour&nbsp;?
          C’est&nbsp;simple&nbsp;et&nbsp;gratuit&nbsp;!
        </span>
      </section>
      <section className={styles.block}>
        <img src={canceling} alt={"canceling"} />
        <span>Retrait gratuit sous 2h dans notre magasin à Paris</span>
      </section>
    </div>
  );
}

export default FooterTop;
