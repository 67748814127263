import React, { useState } from "react";
import { ProductDescription, ProductOpinion } from "../index";
import styles from "./ProductSwitchForm.css";

type ProductSwitchFormProps = {
  product: any;
  onClickFollowBrand?: any;
  error?: any;
  defaultValue?: any;
  disabledButton?: any;
  setFieldValueOpinion?: any;
  isSignedIn: boolean;
  onRedirectToSignIn: () => void;
};

const tabs = [
  {
    key: "description",
    label: "Description",
  },
  {
    key: "delivery-details",
    label: "Livraison et retours",
  },
];

function ProductSwitchForm({
  product,
  onClickFollowBrand,
  setFieldValueOpinion,
  productReviews,
  isSignedIn,
  onRedirectToSignIn,
}: ProductSwitchFormProps) {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const showTab = (index: number) => {
    setActiveTabIndex(index);
  };

  const startTime = new Date("2024-05-03 12:00").getTime() / 1000;
  const endTime = new Date("2024-05-05 23:59").getTime() / 1000;

  const nowTime = new Date().getTime() / 1000;
  let freeShippingThreshold = 60;
  if (nowTime > startTime && nowTime < endTime) {
    freeShippingThreshold = 30;
  }

  return (
    <div className={styles.container} id={"details"}>
      <div className={styles.wrapper}>
        <div className={styles.tabsNavigation}>
          {tabs.map(({ label, key }, index) => (
            <a
              onClick={() => showTab(index)}
              className={
                styles.tab + " " + (activeTabIndex === index ? "active" : "")
              }
              key={key}
            >
              {label}{" "}
              {key === "reviews" && <>({product.rating.reviewsCount})</>}
            </a>
          ))}
        </div>
        <div
          className={
            styles.tabContent +
            " " +
            (tabs[activeTabIndex].key === "description" ? "active" : "")
          }
        >
          <ProductDescription
            product={product}
            onClickFollowBrand={onClickFollowBrand}
          />
        </div>
        <div
          className={
            styles.tabContent +
            " " +
            (tabs[activeTabIndex].key === "delivery-details" ? "active" : "")
          }
        >
          <ul className={styles.deliveryDetails}>
            <li>
              <b>Livraison en France Métropolitaine</b>
              <p>
                Offerte dès {freeShippingThreshold}&euro; d'achat.
                <br />
                À domicile (Colissimo)&nbsp;: 6,90&nbsp;&euro;
                <br />
                En point relais (Mondial&nbsp;Relay)&nbsp;: 4,40&nbsp;&euro;
              </p>
              <b>Livraison en Belgique</b>
              <p>
                Offerte dès {freeShippingThreshold}&euro; d'achat.
                <br />
                À domicile (Colissimo)&nbsp;: 10,50&nbsp;&euro;
                <br />
                En point relais (Mondial&nbsp;Relay)&nbsp;: 7,90&nbsp;&euro;
              </p>
            </li>
            <li>
              <b>Retours</b>
              <p>
                Retours gratuits sous 14 jours et échanges rapides.
                <br />
                Le produit doit être intact, dans son emballage d'origine, et
                accompagné de ses accessoires et de sa notice d'utilisation.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.tabsNavigation}>
          <span className={styles.tab + " active"}>
            Avis ({product.rating.reviewsCount})
          </span>
        </div>
        <ProductOpinion
          product={product}
          productReviews={productReviews}
          setFieldValue={setFieldValueOpinion}
          isSignedIn={isSignedIn}
          onRedirectToSignIn={onRedirectToSignIn}
        />
      </div>
    </div>
  );
}

export default ProductSwitchForm;
