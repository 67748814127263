import React, {useState} from 'react';
import * as styles from './HelpSectionRow.css';
import {FooterHelpPage} from "../index";
import Offers from '../../assets/OffersPageHelp.png';
import Payment from '../../assets/PaymentPageHelp.png';
import {Link} from "react-router-dom";
import {helpOffersData, helpPaymentData} from "../../utils/ArrayHelp&Footer";
import arrowMenu from "../../assets/arrowMenu.png";


type HelpSectionRowProps = {
  section: {
    answer: string,
    question: string,
  };
  key: any;
};

function HelpSectionRow({section, key}: HelpSectionRowProps) {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const toggle = () => setIsCollapsed(!isCollapsed);

  return (
      <div className={styles.dropdown + (!isCollapsed ? ' active' : '')} key={key}>
        <div className={styles.button} onClick={toggle}>
          <h2>{section.question}</h2>
          <img src={arrowMenu}/>
        </div>
        <p dangerouslySetInnerHTML={{__html: section.answer.replace(/\n/g, '<br/>')}}/>
      </div>
  );
}

export default HelpSectionRow;
