import React, { useEffect, useRef, useCallback, useState } from "react";
import cx from "classnames";
import moment from "moment";

import cross from "../../assets/cross.png";

import * as styles from "./FreeShippingPopIn.css";
import { Button, ButtonTypes } from "../Button";

import freeShippingImage from "./fd-free-shipping.jpg";
import freeShippingMobileImage from "./fd-free-shipping-mobile.png";

const calculateDuration = (eventTime: number) =>
  moment.duration(
    Math.max(eventTime - Math.floor(Date.now() / 1000), 0),
    "seconds"
  );

function Countdown({
  eventTime,
  interval,
}: {
  eventTime: number;
  interval: number;
}) {
  const [duration, setDuration] = useState(calculateDuration(eventTime));
  const timerRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const timerCallback = useCallback(() => {
    setDuration(calculateDuration(eventTime));
  }, [eventTime]);

  useEffect(() => {
    timerRef.current = setInterval(timerCallback, interval);

    return () => {
      clearInterval(timerRef.current);
    };
  }, [eventTime]);

  const hours = duration.days() * 24 + duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  return (
    <div style={{ display: "flex", marginBottom: "38px", marginTop: "20px" }}>
      <div>
        <div style={{ textAlign: "center" }}>
          <span className={styles.digits}>
            {hours > 9 ? hours.toString()[0] : "0"}
          </span>
          <span className={styles.digits}>
            {hours > 9 ? hours.toString()[1] : hours.toString()[0]}
          </span>
        </div>
        <div className={styles.units}>heures</div>
      </div>
      <div className={styles.timeSeparator}>:</div>
      <div>
        <div style={{ textAlign: "center" }}>
          <span className={styles.digits}>
            {minutes > 9 ? minutes.toString()[0] : "0"}
          </span>
          <span className={styles.digits}>
            {minutes > 9 ? minutes.toString()[1] : minutes.toString()[0]}
          </span>
        </div>
        <div className={styles.units}>minutes</div>
      </div>
      <div className={styles.timeSeparator}>:</div>
      <div>
        <div style={{ textAlign: "center" }}>
          <span className={styles.digits}>
            {seconds > 9 ? seconds.toString()[0] : "0"}
          </span>
          <span className={styles.digits}>
            {seconds > 9 ? seconds.toString()[1] : seconds.toString()[0]}
          </span>
        </div>
        <div className={styles.units}>secondes</div>
      </div>
    </div>
  );
}

function FreeShippingPopIn() {
  const [visible, setVisible] = useState(true);

  const onClose = () => {
    setVisible(false);
  };

  const nowTime = new Date().getTime() / 1000;
  const startTime = new Date("2024-05-03 12:00").getTime() / 1000; // friday 7:30 am
  let eventTime = new Date("2024-05-05 23:59").getTime() / 1000; // sunday 8am
  let secondEventTime = new Date("2024-05-05 23:59").getTime() / 1000; // sunday 11:59pm

  // hide popin if not in time range
  if (nowTime < startTime || nowTime > secondEventTime) {
    return null;
  }

  // replace eventTime with secondEventTime if eventTime is passed
  if (nowTime > eventTime) {
    eventTime = secondEventTime;
  }

  return (
    <>
      <div className={cx(styles.modal, { visible: visible })} role="alert">
        <div className={styles.cross} onClick={onClose} role="button" />
        <div className={styles.content}>
          <span className={styles.title}>Livraison offerte</span>
          <div className={styles.description}>
            <span>tout le week-end !</span>
            <br />
            <span>dès 30€ d'achat</span>
          </div>
          <Countdown eventTime={eventTime} interval={1000} />
          <img src={freeShippingMobileImage} className={styles.mobileImage} />
          <Button
            text={"Je fonce !"}
            type={ButtonTypes.Primary}
            as={"link"}
            href={"/soldes"}
            onClick={onClose}
            style={{ backgroundColor: "#000", color: "#FFF", borderRadius: 0 }}
          />
        </div>
        <img
          src={freeShippingImage}
          className={styles.desktopImage}
          width="280"
        />
      </div>
      <div
        className={cx(styles.modalBackDrop, { visible: visible })}
        onClick={onClose}
      />
    </>
  );
}

export default FreeShippingPopIn;
